import React from "react";
import App from "./containers/App";
import { BrowserRouter } from "react-router-dom";

const Root = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
};

export default Root;
