import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./NotFound";
import AboutMePersonalPrivacy from "../legal/privacyPolicy/AboutMePersonalPrivacy";
import AboutMePersonalEula from "../legal/eula/AboutMePersonalEula";
import AboutMeProfessionalPrivacy from "../legal/privacyPolicy/AboutMeProfessionalPrivacy";
import AboutMeProfessionalEula from "../legal/eula/AboutMeProfessionalEula";

const App = () => {
  return (
    <Routes>
      <Route path="privacy-policy">
        <Route path="aboutme-personal" element={<AboutMePersonalPrivacy />} />
        <Route
          path="aboutme-professional"
          element={<AboutMeProfessionalPrivacy />}
        />
      </Route>
      <Route path="eula">
        <Route path="aboutme-personal" element={<AboutMePersonalEula />} />
        <Route
          path="aboutme-professional"
          element={<AboutMeProfessionalEula />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
